import "./MainPage.css";
import {ButtonBase, Grid, Typography} from "@mui/material";
import KonzertmeisterAppointmentList from "./KonzertmeisterAppointmentList";

import img_mv_gesamt from '../images/mvw_2019_cropped.jpg';
import img_juka_gesamt from "../images/juka_bilder/0_gruppenbild_vor_altweibermühle.jpg";
import img_mv_kirchenkonzert from "../images/kirchenkonzert_mvw.jpeg";
import img_posaune_basteln from "../images/posaune_basteln.jpeg";
import {useNavigate} from "react-router-dom";


function MainPage() {
    const navigate = useNavigate();

    const handlePageChange = (page) => {
        switch (page) {
            case "Jugendkapelle":
                navigate("/jugend", {state: {"scrollToComponent": null}});
                break;
            case "Ausbildung":
                navigate("/jugend", {state: {"scrollToComponent": "ausbildung"}});
                break;
            default:
                break;
        }
    };

    return (
        <div className="main_page_outer">
            <img
                className="mvw_gesamt_img"
                src={img_mv_gesamt}
                alt="Musikverein Gruppenbild"
                width=""
            />
            <div className="main_page_inner">
                <Grid container
                      spacing={1}
                      className="overview">
                    <Grid item xs={12} md={4} order={{xs: 1, md: 1}}>
                        <ButtonBase onClick={() => handlePageChange("Ausbildung")}>
                            <Typography variant="h4">
                                <strong>Ausbildung</strong>
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 2, md: 4}} className="overview_text">
                        <ButtonBase onClick={() => handlePageChange("Ausbildung")}>
                            <Typography align="left">
                                Bevor unser Nachwuchs in der Jugendkapelle spielen kann, muss natürlich zuerst ein
                                Instrument
                                erlernt werden. Angefangen bei der Blockflöte für die Jüngeren, bis hin zu einem Holz-,
                                Blechblasinstrument oder Schlagzeug wird bei uns im Verein ausgebildet.
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 3, md: 7}}>
                        <ButtonBase onClick={() => handlePageChange("Ausbildung")}>
                            <img
                                className="grid_image"
                                src={img_posaune_basteln}
                                alt="Posaune basteln"
                            />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 4, md: 2}}>
                        <ButtonBase onClick={() => handlePageChange("Jugendkapelle")}>
                            <Typography variant="h4">
                                <strong>Jugendkapelle</strong>
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 5, md: 5}} className="overview_text">
                        <ButtonBase onClick={() => handlePageChange("Jugendkapelle")}>
                            <Typography align="left">
                                In unserer Jugendkapelle, dirigiert von Florian Häfele, lernen junge Musiker das
                                gemeinsame
                                Musizieren
                                in
                                Vorbereitung auf die aktive Kapelle. Neben der Probenarbeit und mehreren Auftritten
                                jedes
                                Jahr
                                steht
                                die Kameradschaft sehr weit vorne.
                            </Typography>
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 6, md: 8}}>
                        <ButtonBase onClick={() => handlePageChange("Jugendkapelle")}>
                            <img
                                className="grid_image"
                                src={img_juka_gesamt}
                                alt="Jugendkapelle"
                            />
                        </ButtonBase>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 7, md: 3}}>
                        <Typography variant="h4">
                            <strong>Aktive Kapelle</strong>
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 8, md: 6}} className="overview_text">
                        <Typography align="left">
                            In der Aktiven Kapelle spielen wir neben zwei Konzerten mit anspruchsvoller
                            Blasmusik-Literatur
                            jedes Jahr diverse Auftritte. Dazu gehören unter anderem verschiedene Gartenfeste,
                            kirchliche
                            Auftritte und Besuche von befreundeten Vereinen.
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={4} order={{xs: 9, md: 9}}>
                        <img
                            className="grid_image"
                            src={img_mv_kirchenkonzert}
                            alt="Musikverein Kirchenkonzert"
                        />
                    </Grid>
                </Grid>
                <Typography variant="h2">
                    Kommende Auftritte
                </Typography>
                <KonzertmeisterAppointmentList className="konzertmeister_appointment_list"/>
            </div>
        </div>
    )
}

export default MainPage