import "./MobileToolBar.css";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import AppBar from "@mui/material/AppBar";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router-dom";
import mvLogo from "./images/mvw_logo_transparent.png";
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import Box from "@mui/material/Box";
import * as React from "react";
import {Divider, ListItemIcon, Menu, MenuItem} from "@mui/material";
import {useEffect, useState} from "react";

const pages = ["Jugendkapelle", "Ausbildung", "Blockflötenunterricht", "Galerie", "Kontakt"];

function MobileToolBar() {
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);

        };

        // Add event listener to listen for window resize
        window.addEventListener('resize', handleResize);

        // Remove event listener when component unmounts
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePageChange = (page) => {
        switch (page) {
            case "Jugendkapelle":
                navigate("/jugend", {state: {"scrollToComponent": null}});
                break;
            case "Ausbildung":
                navigate("/jugend", {state: {"scrollToComponent": "ausbildung"}});
                break;
            case "Blockflötenunterricht":
                navigate("/jugend", {state: {"scrollToComponent": "blockfloete"}});
                break;
            case "Galerie":
                navigate("/galerie");
                break;
            case "Kontakt":
                navigate("/kontakt");
                break;
            case "Home":
                navigate("/");
                break;
            default:
                break;
        }
        handleClose();
    };

    return (
        <AppBar color="background" position="static">
            <Container className="toolbar">
                <Toolbar disableGutters>
                    <Box sx={{flexGrow: 1}}>
                        <IconButton
                            onClick={() => handlePageChange("Home")}
                        >
                            <img className="mvwLogoMobile" src={mvLogo} alt={"MVW Logo"}/>
                        </IconButton>
                    </Box>
                    <Box sx={{flexGrow: 0}}>
                        <IconButton
                            variant="link"
                            color="primary"
                            onClick={handleClick}
                        >
                            <MenuIcon fontSize="large"/>
                        </IconButton>
                        <Menu
                            className="mobileToolBar"
                            marginThreshold={0}
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                            position="relative"
                            anchorReference="anchorPosition"
                            anchorPosition={{top: 0, left: screenWidth * 0.5}}
                            transitionDuration={0}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            PaperProps={{
                                style: {
                                    width: "50%",
                                    height: "100%",
                                    maxHeight: "100%",
                                    backgroundColor: '#851a34',
                                    borderRadius: 0,
                                    color: 'white',
                                },
                            }}
                        >
                            <MenuItem onClick={handleClose}>
                                <ListItemIcon>
                                    <CloseIcon fontSize="large" color="background"/>
                                </ListItemIcon>
                            </MenuItem>
                            {pages.map((page) => (
                                <div>
                                    <MenuItem
                                        key={page}
                                        onClick={() => handlePageChange(page)}
                                    >
                                        {page}
                                    </MenuItem>
                                    <Divider sx={{backgroundColor: 'white'}}/>
                                </div>
                            ))}
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

export default MobileToolBar;