import React, {useEffect} from 'react';

function KonzertmeisterAppointmentList() {
    useEffect(() => {
        const handleMessage = (event) => {
            const frame = document.getElementById('kmAppointmentListFrame');
            if (
                event &&
                event.data &&
                event.data.kmAppointmentListHeight &&
                typeof event.data.kmAppointmentListHeight === 'number' &&
                frame
            ) {
                const borderWidth = isNaN(frame.style.borderWidth.replace('px', ''))
                    ? 0
                    : parseInt(frame.style.borderWidth.replace('px', ''), 10);
                document.getElementById('kmAppointmentListFrame').style.height =
                    event.data.kmAppointmentListHeight + 2 * borderWidth + 'px';
            }
        };

        window.addEventListener('message', handleMessage);

        return () => {
            window.removeEventListener('message', handleMessage);
        };
    }, []);

    return (
        <iframe
            id="kmAppointmentListFrame"
            title="kmAppointmentListFrame"
            style={{
                display: 'block',
                boxSizing: 'border-box',
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
                border: 'solid 1px #000',
            }}
            src="https://rest.konzertmeister.app/api/v3/org/OALS_ead5d532-ba58-44fe-aac6-e03e59d31ec2/upcomingappointments?types=2,6&limit=5&display=light&showDescription=false&hash=0519362da25c235a574360688a234d554a20dd90e1b5f7a846ca3ad45c1bc0bf"
        ></iframe>
    );
}

export default KonzertmeisterAppointmentList;
