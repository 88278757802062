import './App.css';
import ToolBar from "./ToolBar";
import {Outlet} from "react-router-dom";
import Footer from "./Footer.js";
import MobileToolBar from "./MobileToolBar";

function App() {
   function chooseToolBar() {
       if (window.innerWidth < 720)
            return <MobileToolBar/>
       else
           return <ToolBar/>
   }

    return (
        <div className="App">
            {chooseToolBar()}
            <div className="outlet">
                <Outlet/>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
