import './Galerie.css'

import {useEffect, useState} from "react";
import {ButtonBase, ImageList, ImageListItem, Typography} from "@mui/material";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import {useMediaQuery} from "@mui/system";

function Galerie() {
    const [events, setEvents] = useState([]);
    const [lightBoxesOpen, setLightBoxesOpen] = useState([]);
    const [lightBoxIndex, setLightBoxIndex] = useState(0);

    const isSmallScreen = useMediaQuery('(max-width:600px)');
    const cols = isSmallScreen ? 3 : 4;

    const openLightBox = (lightBoxName, index) => {
        setLightBoxIndex(index)
        setLightBoxesOpen(prevState => [...prevState, lightBoxName]);
    };

    const closeLightBox = (itemToRemove) => {
        setLightBoxesOpen(prevState => prevState.filter(item => item !== itemToRemove));
        setLightBoxIndex(0)
    };

    useEffect(() => {
        const getEvents = (r) => {
            const events = [];
            r.keys().forEach((file) => {
                const dirName = file.split('/')[1]; // Extracting directory name from file path
                const imagePath = require('../images/gallery/' + file.split('/')[1] + '/' + file.split('/')[2])
                if (!events[dirName]) {
                    events[dirName] = [];
                }
                events[dirName].push(imagePath)
            });
            return events;
        }
        const events = getEvents(require.context('../images/gallery/', true, /\.(png|jpe?g|JPG|svg)$/));
        setEvents(events);
    }, []);

    function convertDirName(dirName) {
        const [unformattedDate, eventName] = dirName.split("_");
        const [year, month, day] = unformattedDate.split("-");
        const date = `${day}.${month}.${year}`
        return [eventName, date]
    }

    function convertToSplit(images) {
        const split = [];
        images.forEach((image) => {
            split.push({"src": image})
        })
        return split;
    }

    return (
        <div className="gallery_outer">
            <div className="gallery">
                {Object.entries(events).toReversed().map(([dirName, images], index) => (
                    <div key={index}>
                        <Typography variant="h2">
                            {convertDirName(dirName)[0]}
                        </Typography>
                        <Typography >
                            {convertDirName(dirName)[1]}
                        </Typography>
                        <ImageList
                            cols={cols}
                            spacing={3}
                            variant="masonry"
                        >

                            {images.map((image, index) => (
                                <ButtonBase
                                    key={index}
                                    onClick={() => openLightBox(dirName, index)}
                                >
                                    <ImageListItem>
                                        <img
                                            className="gallery_image"
                                            src={image}
                                            alt={image}
                                            key={index}
                                        />
                                    </ImageListItem>
                                </ButtonBase>
                            ))}
                        </ImageList>
                        <Lightbox
                            open={lightBoxesOpen.includes(dirName)}
                            close={() => closeLightBox(dirName)}
                            slides={convertToSplit(images)}
                            key={dirName}
                            index={lightBoxIndex}
                        />
                    </div>
                ))}

            </div>
        </div>
    );
}

export default Galerie;