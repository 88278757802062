import "./Kontakt.css";
import {Avatar, Grid, Link, Typography} from "@mui/material";
import imgMikey from "../images/mikey.png"
import imgRobert from "../images/image-1.png"
import imgAlex from "../images/alex.png"
import * as React from "react";

const imageStyle = {
    width: 80,
    height: 80,
'@media (min-width: 600px)': {
    width: 130,
    height: 130,
},
}

function Kontakt() {
    const vorstandEmail = '1.vorstand@musikverein-wissgoldingen.de';
    const vorstandEmailLink = `mailto:${vorstandEmail}`;
    const vorstandMobile = "0171 / 7967421"
    const vorstandMobileLink = `tel:${vorstandMobile}`
    const vorstandTel = "07332 / 309115"
    const vorstandTelLink = `tel:${vorstandTel}`
    const dirigentEmail = 'robertglaser@web.de';
    const dirigentEmailLink = `mailto:${dirigentEmail}`;
    const jugendEmail = 'alexhoran@arcor.de';
    const jugendEmailLink = `mailto:${jugendEmail}`;
    const jugendMobile = "0151 / 68193110"
    const jugendMobileLink = `tel:${jugendMobile}`

    return (
        <div className="kontakt_outer">
            <Typography variant="h2">
                <strong>Kontakt</strong>
            </Typography>
            <Grid container className="kontakt_inner">
                <Grid item xs="4" className="avatar_wrapper">
                    <Avatar
                        alt="Avatar image"
                        src={imgMikey}
                        sx={imageStyle}
                    />
                </Grid>
                <Grid item xs="8" className="text_wrapper">
                    <div>
                        <Typography variant="h4">
                            1. Vorstand: Michael Wiget
                        </Typography>
                        <Typography>
                            Friedhofstraße 32
                        </Typography>
                        <Typography>
                            73111 Lauterstein - Nenningen
                        </Typography>
                        <Typography>
                            <strong>Mail: </strong>
                            <Link href={vorstandEmailLink} color="inherit">
                                {vorstandEmail}
                            </Link>
                        </Typography>
                        <Typography>
                            <strong>Telefon: </strong>
                            <Link href={vorstandTelLink} color="inherit">
                                {vorstandTel}
                            </Link>
                        </Typography>
                        <Typography>
                            <strong>Mobil: </strong>
                            <Link href={vorstandMobileLink} color="inherit">
                                {vorstandMobile}
                            </Link>
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs="4" className="avatar_wrapper">
                    <Avatar
                        alt="Avatar image"
                        src={imgRobert}
                        sx={imageStyle}
                    />
                </Grid>
                <Grid item xs="8" className="text_wrapper">
                    <div>
                        <Typography variant="h4">
                            Dirigent: Robert Glaser
                        </Typography>
                        <Typography>
                            Ziegelstr. 32
                        </Typography>
                        <Typography>
                            73084 Salach
                        </Typography>
                        <Typography>
                            <strong>Mail: </strong>
                            <Link href={dirigentEmailLink} color="inherit">
                                {dirigentEmail}
                            </Link>
                        </Typography>
                    </div>
                </Grid>
                <Grid item xs="4" className="avatar_wrapper">
                    <Avatar
                        alt="Avatar image"
                        src={imgAlex}
                        sx={imageStyle}
                    />
                </Grid>
                <Grid item xs="8" className="text_wrapper">
                    <div>
                        <Typography variant="h4">
                            Jugendleiter: Alex Horan
                        </Typography>
                        <Typography>
                            Am Rackerzell 4
                        </Typography>
                        <Typography>
                            73550 Waldstetten
                        </Typography>
                        <Typography>
                            <strong>Mail: </strong>
                            <Link href={jugendEmailLink} color="inherit">
                                {jugendEmail}
                            </Link>
                        </Typography>
                        <Typography>
                            <strong>Mobil: </strong>
                            <Link href={jugendMobileLink} color="inherit">
                                {jugendMobile}
                            </Link>
                        </Typography>
                    </div>
                </Grid>
            </Grid>
        </div>
    )
}

export default Kontakt;