import "./Impressum.css";
import {Link, Typography} from "@mui/material";
import * as React from "react";

function Impressum() {
    const emailAddress = '1.vorstand@musikverein-wissgoldingen.de';
    const mailtoLink = `mailto:${emailAddress}`;

    return (
        <div className="impressum_flex_div">
            <div className="impressum">
                <Typography variant="h4">
                    Anbieterkennzeichnung
                </Typography>
                <Typography>
                    <strong>Anbieter dieses Internet-Angebots im Sinne von § 6 TDG bzw. § 6 Abs. 1 MDStV ist
                        der:</strong>
                </Typography>
                <Typography>
                    Musikverein Harmonie Wiẞgoldingen 1930 e. V.
                </Typography>
                <Typography>
                    Michael Wiget
                </Typography>
                <Typography>
                    Friedhofstrasse 32
                </Typography>
                <Typography>
                    <strong>E-Mail: </strong>
                    <Link href={mailtoLink} color="inherit">
                        {emailAddress}
                    </Link>
                </Typography>
                <Typography>
                    <strong>VertretungsberechtigterVorsitzender:</strong> Michael Wiget
                </Typography>
                <Typography>
                    <strong>Registergericht:</strong> Amtsgericht Ulm
                </Typography>
                <Typography>
                    <strong>Registernummer:</strong> VR 700270
                </Typography>
                <Typography>
                    <strong>Datum der Eintragung:</strong> 28.04.1970
                </Typography>
                <Typography>
                    <strong>Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV:</strong> Michael Wiget
                </Typography>
                <Typography>
                    Für die Inhalte einzelner Artikel sind die auf den jeweiligen Seiten namentlich genannten Autoren
                    verantwortlich.
                </Typography>
                <Typography variant="h4">
                    Haftungsausschluss
                </Typography>
                <Typography>
                    Gem. §6 MDStV (in der Fassung vom 01.07.2002) sind Anbieter für die eigenen Inhalte, die sie zur
                    Nutzung
                    bereithalten, verantwortlich. Von diesen eigenen Inhalten sind Verweise auf Inhalte anderer Anbieter
                    zu
                    unterscheiden. Die Inhalte fremder Web-Seiten, auf die mittels Hyperlinks verwiesen wird, dienen
                    lediglich der weiterführenden Information. Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir
                    keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind
                    ausschließlich
                    deren Betreiber verantwortlich. Der Inhalt fremder Webseiten kann jederzeit ohne Wissen des
                    Musikverein
                    Harmonie Wißgoldingen 1930 e.V. geändert werden. Sollte von den Webseiten des Musikverein Harmonie
                    Wiẞgoldingen 1930 e. V. auf Seiten verwiesen werden, deren Inhalt Anlass zur Beanstandung gibt,
                    bitten
                    wir um Mitteilung.
                    Der Musikverein Harmonie Wißgoldingen 1930 e. V. behält sich das Recht vor, Änderungen an den
                    Webseiten
                    ohne vorherige Ankündigung vorzunehmen. Wir übernehmen darüber hinaus keine Gewähr für die
                    Aktualität.
                    Korrektheit und Vollständigkeit der auf den Internetseiten bereitgestellten Informationen.
                </Typography>
                <Typography variant="h4">
                    Urheberrecht
                </Typography>
                <Typography>
                    Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
                    deutschen
                    Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb
                    der
                    Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw.
                    Erstellers.
                    Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
                </Typography>
                <Typography>
                    Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
                    Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
                    trotzdem
                    auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
                    Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
                </Typography>
            </div>
        </div>
    );
}

export default Impressum;